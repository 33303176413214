<template>
  <a-card>
    <a-page-header
        title="标签筛选结果"
    />

    <a-row class="flex">
      <a-col flex="auto">
        <div>我的经销商分组（公共分组）：{{ group_name }}</div>
      </a-col>
      <a-col class="tools">
        <a-button type="primary" @click="exportFileList" :loading="exportLoading">导出</a-button>
      </a-col>
    </a-row>

    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :row-key="(record, index) => index"
        :pagination="false"
    >
      <div slot="area" slot-scope="text, record">
        <div class="row-box">
          <div class="title title-1"><span>大</span><span>区：</span></div>
          <div class="text1">{{ record.regionNames || "-" }}</div>
        </div>
        <div class="row-box">
          <div class="title title-1"><span>城</span><span>市：</span></div>
          <a-tooltip>
            <template #title>{{ record.cityName }}</template>
            <div class="text1">{{ record.cityName || "-" }}</div>
          </a-tooltip>
        </div>
      </div>
      <div slot="tags" slot-scope="text, record">
        <div class="row-box"
             v-for="(item,index) of record.labelsList"
             :key="index"
             v-if="record.labelsList && record.labelsList.length > 0">
          <div class="title title-2">
            <span style="color:#40a9ff;">{{ item.nameThree || "-" }}：</span>
          </div>
          <a-tooltip>
            <template #title>{{ item.nameFour }}</template>
            <div class="text1">{{ item.nameFour || "-" }}</div>
          </a-tooltip>
        </div>
        <div v-else>暂无标签</div>
      </div>
      <div slot="action" slot-scope="text, record">
        <base-button
            :type="'link'"
            :title="'查看详情'"
            @onClickBtn="jumpDetailPage(record.id)"
        ></base-button>
      </div>
    </a-table>

    <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
import Api from "@/api/tagManage";

export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          align: "center",
          title: "经销商名称",
          dataIndex: "name",
          width: "200px",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "编码",
          dataIndex: "storeCode",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "公司名称",
          dataIndex: "companyName",
          width: "15%",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "主体",
          dataIndex: "principalNames",
          width: "15%",
          customRender(text) {
            return text || "-";
          }
        },
        {
          align: "center",
          title: "地区",
          scopedSlots: {customRender: "area"}
        },
        {
          align: "center",
          title: "诊断标签",
          width: "24%",
          scopedSlots: {customRender: "tags"}
        },
        {
          align: "center",
          title: "操作",
          key: "action",
          width: 100,
          scopedSlots: {customRender: "action"}
        }
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      group_name: "",
      tagsData: {},
      exportLoading: false,
      form: {}
    };
  },
  mounted() {
    const {tagsData, group_name} = this.$route.query;
    if(tagsData, group_name) {
      this.group_name = group_name;
      this.tagsData = JSON.parse(tagsData);
      this.getLabelDiagnoseData();
    }
  },
  methods: {
    getLabelDiagnoseData() {
      this.loading = true;
      let params = {
        // principalId: this.principalId,
        // regionId: this.regionId,
        effectStartTime: this.tagsData.effectStartTime,
        effectEndTime: this.tagsData.effectEndTime,
        labelIds: this.tagsData.labelIds,
        groupId: this.tagsData.groupId,
        isCommon: this.tagsData.isCommon,
        size: this.pagination.pageSize,
        page: this.pagination.current
      };
      Api.getLabelDiagnoseData(params).then((res) => {
        this.loading = false;
        if(res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.$message.error("获取数据失败");
          this.dataList = [];
          this.pagination.total = 0;
        }
      });
    },
    async exportFileList() {
      this.exportLoading = true;
      try {
        let params = {
          // principalId: this.principalId,
          // regionId: this.regionId,
          effectStartTime: this.tagsData.effectStartTime,
          effectEndTime: this.tagsData.effectEndTime,
          labelIds: this.tagsData.labelIds,
          groupId: this.tagsData.groupId,
          isCommon: this.tagsData.isCommon,
          size: this.pagination.pageSize,
          page: this.pagination.current
        };
        await Api.exportLabelDiagnoseData(params);
        this.exportLoading = false;
      } catch(e) {
        console.error(e);
        this.exportLoading = false;
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getLabelDiagnoseData();
    },
    jumpDetailPage(id) {
      let routeUrl = this.$router.resolve({
        name: "dealerDetail",
        query: {
          dealer_id: id
        }
      });

      window.open(routeUrl.href, "_blank");
    },
  }
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  .title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    &-1 {
      width: 50px;
    }

    &-2 {
      min-width: 44px;
      max-width: 150px;
      text-align: left;
      white-space:nowrap
    }
  }

  .text1 {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}
</style>
